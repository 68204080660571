// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForgotPass-container{
    text-align: center;
  padding: 1rem;
  align-items: center;
  justify-items: center;
  display: flex;
  flex-direction: column;
  }

  .ForgotPass__header {
    color: #000;
  font-family: "Inter" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
  }
  .forgotpass-btn-styles {
    width: 76%;
    border-radius: 13px;
    background: #0d0d0d;
    font-weight: 600;
  }

  @media all and (max-width: 750px) {
    .forgotpass-btn-styles{
      width: 100%;
    }
    .text-input{
      width: 100%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/modules/LandingPage/Auth/ForgotPassword/index.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;EACb,sBAAsB;EACtB;;EAEA;IACE,WAAW;EACb,+BAA+B;EAC/B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB;EACA;IACE,UAAU;IACV,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE;MACE,WAAW;IACb;IACA;MACE,WAAW;IACb;EACF","sourcesContent":[".ForgotPass-container{\n    text-align: center;\n  padding: 1rem;\n  align-items: center;\n  justify-items: center;\n  display: flex;\n  flex-direction: column;\n  }\n\n  .ForgotPass__header {\n    color: #000;\n  font-family: \"Inter\" !important;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: normal;\n  margin-top: 10px;\n  }\n  .forgotpass-btn-styles {\n    width: 76%;\n    border-radius: 13px;\n    background: #0d0d0d;\n    font-weight: 600;\n  }\n\n  @media all and (max-width: 750px) {\n    .forgotpass-btn-styles{\n      width: 100%;\n    }\n    .text-input{\n      width: 100%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
