// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 
.avatars-container{
    position: relative;
}

.avatar-img{
    position: absolute;
    right: 10px;
} */`, "",{"version":3,"sources":["webpack://./src/components/AvatarGroups/index.css"],"names":[],"mappings":"AAAA;;;;;;;;GAQG","sourcesContent":["/* \n.avatars-container{\n    position: relative;\n}\n\n.avatar-img{\n    position: absolute;\n    right: 10px;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
